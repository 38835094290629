
const USER_LOGIN = "USER_LOGIN"

export default {
    actions: {
        login({state, commit},user) {
            commit(USER_LOGIN, user)
            localStorage.setItem("LES_BOUQ_GAT", JSON.stringify(user))
        },
        loadUser({state,commit}) {
            const now = new Date();
            if(localStorage.getItem("LES_BOUQ_GAT")) {
                const user = JSON.parse(localStorage.getItem("LES_BOUQ_GAT"))
                let expirationDate = new Date(user?.date)
                expirationDate = expirationDate.setDate(expirationDate.getDate() + 2*7)
                if(now.getTime() > expirationDate) {
                    localStorage.removeItem("LES_BOUQ_GAT")
                }
                else {
                    commit(USER_LOGIN, user)
                }
            }
        }
    },
    getters: {
        getUser({state}) {
            return state.user
        }
    },
    mutations: {
        [USER_LOGIN]: (state, user) => {
            state.user = user
        }
    },
    namespaced: true,
    state: {
        user: null,
    },
}