import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: { requiresAuth: false, robots: "noindex"  },
  },
  {
    path: '/ajouter',
    name: 'AJouter',
    component: () => import('@/views/authentication/Ajouter.vue'),
    meta: { requiresAuth: true, robots: "noindex"  },
  },
  {
    path: '/gestion',
    name: 'Gestion',
    component: () => import('@/views/authentication/Gestion.vue'),
    meta: { requiresAuth: true, robots: "noindex"  },
  },

]

const router = new VueRouter({
  base: '/',
  routes
})

router.beforeResolve ((to, from, next) => {
  const authentication = store.state?.authentication?.user?.secret
  if(to.meta.requiresAuth && to.fullPath !== '/login' && !authentication) {
    next({ name: 'Login' })
  }
  next();
});

export default router
